<template>
  <div id="report_member" class="container--fluid">
    <v-card>
      <v-card-title>
        รายงานข้อมูลสมาชิก
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="gray" @click="onExport">Export</v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.$store.getters.getlistMemberReport"
        :search="search"
      >
        <template v-slot:[`item.mem_fullname`]="{ item }"
          >{{ item.PreName + item.mem_fname + " " + item.mem_lname }}
        </template>
        <template v-slot:[`item.mem_birthday_th`]="{ item }"
          >{{ item.mem_birthday | ConvDateToDMY_TH }}
        </template>
        <template v-slot:[`item.mem_regis_date_th`]="{ item }"
          >{{ item.mem_regis_date | ConvDateToDMY_TH }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  name: "report_member",
  data() {
    return {
      desserts: [],
      search: "",
      headers: [
        { text: "รหัสสมาชิก", value: "mem_id" },
        { text: "เลขบัตรประชาชน", value: "mem_people_id" },
        { text: "ชื่อ-นามสกุล", value: "mem_fullname" },
        { text: "หมู่บ้าน", value: "vil_name" },
        { text: "ที่อยู่", value: "mem_address" },
        { text: "ตำบล", value: "LocName" },
        { text: "อำเภอ", value: "DisName" },
        { text: "จังหวัด", value: "ProName" },
        { text: "เบอร์โทร", value: "mem_phone" },
        { text: "วันเดือนปีเกิด", value: "mem_birthday_th" },
        { text: "วันที่สมัคร", value: "mem_regis_date_th" },
        { text: "ผู้รับผลประโยชน์", value: "mem_receipt" },
      ],
    };
  },
  methods: {
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(
        this.$store.getters.getlistMemberReport
      );
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "report_member.xlsx");
    },
  },
  mounted() {
    this.$store.dispatch("getlistMemberReport");
  },
  filters: {
    ConvDateToDMY_TH: (dateTxt) => {
      let dArr = dateTxt.split(" ");
      let dtArr = dArr[0].split("-");
      return (
        dtArr[2] + "/" + dtArr[1] + "/" + (parseInt(dtArr[0]) + 543).toString()
      );
    },
  },
};
</script>

<style>
</style>